import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"

const StyledTileMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;
  gap: 0.5em;

  &::before {
    content: "";
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  .box {
    background-color: #f07e14;
    color: black;
    width: 100%;
    height: 100%;
    min-width: 100%;
    padding: 1em;
    box-sizing: border-box;
    hyphens: auto;
    text-align: center;
    cursor: pointer;
    line-height: 1.5em;
    // font-weight: bold;
    font-size: 1em;
    font-size: 1.3vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #ffb147;
    }

    .top {
      font-weight: bold;
    }
  }
`

const TileMenuSpritzgiessen = () => {
  const data = useStaticQuery(graphql`
    query {
      k1: contentfulCourse(courseId: { eq: "k1" }) {
        courseName
        price
        slug
      }
      k2: contentfulCourse(courseId: { eq: "k2" }) {
        courseName
        price
        slug
      }
      k3: contentfulCourse(courseId: { eq: "k3" }) {
        courseName
        price
        slug
      }
      k4: contentfulCourse(courseId: { eq: "k4" }) {
        courseName
        price
        slug
      }
      k5: contentfulCourse(courseId: { eq: "k5" }) {
        courseName
        price
        slug
      }
    }
  `)

  return (
    <StyledTileMenu>
      <Link to={data.k1.slug} className="box box--primary">
        <div className="top">{data.k1.courseName}</div>
        <div className="bottom">
          2&nbsp;Tage
          <br />
          {data.k1.price} €
        </div>
      </Link>
      <Link to={data.k2.slug} className="box box--primary">
        <div className="top">{data.k2.courseName}</div>
        <div className="bottom">
          2&nbsp;Tage
          <br />
          {data.k2.price} €
        </div>
      </Link>
      <Link to={data.k3.slug} className="box box--primary">
        <div className="top">{data.k3.courseName}</div>
        <div className="bottom">
          2&nbsp;Tage
          <br />
          {data.k3.price} €
        </div>
      </Link>
      <Link to={data.k4.slug} className="box box--primary">
        <div className="top">{data.k4.courseName}</div>
        <div className="bottom">
          2&nbsp;Tage
          <br />
          {data.k4.price} €
        </div>
      </Link>
      <Link to={data.k5.slug} className="box box--primary">
        <div className="top">{data.k5.courseName}</div>
        <div className="bottom">
          2&nbsp;Tage
          <br />
          {data.k5.price} €
        </div>
      </Link>
    </StyledTileMenu>
  )
}

export default TileMenuSpritzgiessen
