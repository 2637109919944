import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import TileMenuSpritzgiessen from "../components/TileMenuSpritzgiessen"

export const query = graphql`
  query {
    cardImage: contentfulAsset(title: { eq: "Spritzgießführerschein Card" }) {
      fluid(maxWidth: 400) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    boxImage: contentfulAsset(title: { eq: "Spritzgießführerschein Box" }) {
      fluid(maxWidth: 500) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`

const StyledSpritzgiessenPage = styled.div`
  .intro {
    text-align: center;
    margin-bottom: 2rem;
  }

  .driver-license {
    display: flex;
    max-width: 80%;
    margin: auto;

    .gatsby-image-wrapper {
      width: 100%;

      img {
        object-fit: contain !important;
      }
    }
  }

  .savings {
    background-color: var(--primary);
    color: white;
    width: 100%;
    font-weight: bold;
  }
`

const SpritzgiessenPage = props => {
  return (
    <Layout>
      <SEO title="Spritzgießführerschein" />
      <StyledSpritzgiessenPage className="container">
        <div className="my-5">
          <TileMenuSpritzgiessen />
        </div>
        <h1 className="text-center mb-5 text-uppercase">
          <strong>
            Was ist der <span className="text-orange">Spritzgieß</span>
            führerschein®?
          </strong>
        </h1>
        <div className="intro">
          <p>
            Der <span className="text-orange">Spritzgieß</span>führerschein® ist
            ein Zertifikat, vergleichbar mit dem Autoführerschein oder
            Jägerprüfung.
          </p>

          <p>
            Die Berechtigung erhält man erst, wenn die Prüfung dazu bestanden
            wurde.
          </p>

          <p>
            Wer den <span className="text-orange">Spritzgieß</span>führerschein®
            absolviert hat, versteht die Grundlagen der Spritzgießtechnik und
            alle Spritzgießtechnologien.
          </p>

          <p>
            Auf keinen Fall ist der{" "}
            <span className="text-orange">Spritzgieß</span>führerschein® nur
            eine Teilnahmebescheinigung, wie dies vielfach bei vergleichbaren
            Schulungsangeboten der Fall ist. Bei uns wird mündlich, praktisch
            und schriftlich geprüft!
          </p>
        </div>
        <div className="driver-license">
          {props.data.cardImage && <Img fluid={props.data.cardImage.fluid} />}
          {props.data.boxImage && <Img fluid={props.data.boxImage.fluid} />}
        </div>
      </StyledSpritzgiessenPage>
    </Layout>
  )
}

export default SpritzgiessenPage
